import React, { ReactNode, useMemo } from 'react';
import { styled } from '../../theme';

import { List, ListItem } from '../list';
import { useCollectionState, UseCollectionStateInit } from './collection-state';
import { Row } from 'react-table';
import { StringRecord } from './types';

const StyledCollectionList = styled.div`
`;

export type CollectionListProps<ColData, ColExtra> =
  UseCollectionStateInit<ColData, ColExtra>

export function CollectionList<ColData extends StringRecord, ColExtra>({ ...props }: CollectionListProps<ColData, ColExtra>) {
  console.log('CollectionList')
  const { tableInstance, features } = useCollectionState(props);
  console.log('CollectionList', tableInstance)
  const { page, rows, prepareRow } = tableInstance;

  const items = page ?? rows;

  const [primaryColumn] = useMemo(() => {
    const primaryColIndex = features.primaryColumn
      ? tableInstance.columns.findIndex(col => features.primaryColumn!!.key === col.id)
      : 0;

    return [tableInstance.columns[primaryColIndex], [...tableInstance.columns].splice(primaryColIndex, 1)];
  }, [tableInstance.columns, features.primaryColumn]);

  const renderRow = (row: Row<StringRecord>) => {
    prepareRow(row);

    let primaryCell: ReactNode | null = null;
    let secondaryCells: ReactNode[] = [];

    row.cells.forEach(cell => {
      if (cell.column.id === primaryColumn.id) {
        primaryCell = <p style={{ fontSize: '1.5rem' }}>{cell.render('Cell')}</p>
      } else {
        secondaryCells.push((
          <p>{cell.render('Cell')}</p>
        ));
      }
    });


    return (
      <ListItem key={row.id}>
        {primaryCell}
        {secondaryCells}

        {/* {JSON.stringify(row.cells.map(cell => cell.value))} */}
      </ListItem>
    );
  };

  return (
    <StyledCollectionList>
      <List>
        {items.map(renderRow)}
      </List>
    </StyledCollectionList>
  );
}

// const listCells = (row: Row<StringRecord>, tableInstance: CollectionTableInstance, primaryColumn?: CollectionColumn): [ReactNode, ReactNode[]] => {
//   const primaryColIndex = tableInstance.columns.findIndex(col => col.isP)

//   return ['', []]
// }