import React, { useEffect, useState } from 'react';
import styled from '../../theme/themed-component';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

const StyledTabbedView = styled.div`
  .tabs {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: 0.25rem;
  }

  .tab-header {
    width: 7.5rem;
    min-width: 7.5rem;
    max-width: 10rem;
    max-height: 2rem;

    font-size: 0.75rem;

    /* display: flex;
    align-items: center; */

    background-color: white;
    padding: 0.5rem;

    cursor: pointer;
    border: 1.5px solid black;
    border-radius: 6px 6px 6px 6px;
    /* box-shadow: rgba(0, 0, 0, 0.74) 7px 4px 6px 0px; */
    
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

  }
  

  .tab-header.active {
    border-color: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.primary};
  }

  .tabs > .tab-header:not(:last-child) {
    margin-right: 0.5rem;
  }

  .scroll-indicator {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .dot {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.2rem;
    background-color: #C4C5C6;
    border-radius: 50%;
    display: inline-block;
  }

  .tab-content {
    margin-top: 0.33rem;
  }

  .hidden {
    display: none;
  }
`;


export type UseActiveTabHash = [Tab, React.Dispatch<React.SetStateAction<Tab>>];

export const useActiveTabHash = (tabs: Tab[]): UseActiveTabHash => {
  const history = useHistory();

  const activeTabFromHash = () => {
    let hash = window.location.hash;
    if (hash && hash.startsWith('#')) hash = hash.substring(1);
    console.log('activeTabFromHash', hash)
    return tabs.find(tab => tab.key === hash);
  }

  const [activeTab, setActiveTab] = useState<Tab>(activeTabFromHash() ?? tabs[0]);

  const onHashChange = () => {
    console.log('onHashChange')
    let hash = window.location.hash;
    if (hash && hash.startsWith('#')) hash = hash.substring(1);
    const tab = tabs.find(tab => tab.key === hash);
    if (tab && tab.key !== activeTab.key) setActiveTab(tab);
  }

  useEffect(() => onHashChange(), [history.location.hash]);

  useEffect(() => {
    let hash = window.location.hash;
    if (hash && hash.startsWith('#')) hash = hash.substring(1);

    if (activeTab.key !== hash) {
      // using history.replace instead of setting hash directly leads to 
      //   -> changing tabs does not count as navigation
      //   -> back button goes back to last page, not tab 
      const { pathname, search } = history.location;
      history.replace(pathname + search + '#' + activeTab.key);
      // window.location.hash = activeTab.key;
    }
  }, [activeTab]);

  return [activeTab, setActiveTab];
}


export type Tab = {
  key: string,
  header: React.ReactNode,
  renderContent: () => React.ReactNode
}

export type TabbedViewProps = {
  tabs: Tab[],
  className?: string,
  activeTab: Tab,
  onActiveTabChanged: (tab: Tab) => void,
};

export const hasHorizontalScrollBar = (element: Element) => element.scrollWidth > element.clientWidth;

export const TabbedView: React.FC<TabbedViewProps> = ({ className, tabs, onActiveTabChanged, activeTab }) => {
  if (!tabs) return null;

  // const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);
  const activateTab = React.useCallback((tab: Tab) => onActiveTabChanged(tab), [onActiveTabChanged]);

  const headers = React.useMemo(() => tabs.map(tab => {
    const isActive = tab.key === activeTab.key;
    return (
      <div
        className={"tab-header" + (isActive ? ' active' : '')}
        onClick={activateTab.bind(null, tab)}
        key={tab.key}>
        {/* <span> */}
        {tab.header}
        {/* </span> */}
      </div>
    )
  }), [tabs, activeTab]);

  // const [memoizedContents, setMemoizedContents] = useState<{ [tabKey: string]: React.ReactNode }>({});
  // const content: React.ReactNode = React.useMemo(() => {
  //   console.log(activeTab)
  //   if (!activeTab) return null;
  //   // return activeTab.renderContent();
  //   if (!memoizedContents.hasOwnProperty(activeTab.key)) {
  //     const nextMemoizedContents = { ...memoizedContents };
  //     nextMemoizedContents[activeTab.key] = activeTab.renderContent();
  //     setMemoizedContents(nextMemoizedContents);
  //     return nextMemoizedContents[activeTab.key];
  //   } else {
  //     return memoizedContents[activeTab.key];
  //   }

  // }, [activeTab, memoizedContents]);

  // const content: React.ReactNode = React.useMemo(() => {
  //   return activeTab?.renderContent();
  // }, [activeTab]);

  const [memoizedContents, setMemoizedContents] = useState<{ [tabKey: string]: React.ReactNode }>({});
  useEffect(() => {
    if (!activeTab) return;

    if (!memoizedContents.hasOwnProperty(activeTab.key)) {
      console.log('render', activeTab.key)
      const nextMemoizedContents = { ...memoizedContents };
      nextMemoizedContents[activeTab.key] = activeTab.renderContent();
      setMemoizedContents(nextMemoizedContents);
    }

  }, [activeTab, memoizedContents]);

  return (
    <StyledTabbedView className={className}>
      <div
        className="tabs"
      // ref={setHeaderRef}
      >
        {headers}
      </div>

      {/* {
        headerRef && hasHorizontalScrollBar(headerRef)
          ? (
            <div className="scroll-indicator">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>

          )
          : null
      } */}


      {
        tabs.map(tab => {
          return (
            <div
              key={`tab-${tab.key}`}
              className={classNames('tab-content', { hidden: tab.key !== activeTab?.key })}
            >
              {memoizedContents[tab.key]}
            </div>
          );
        })
      }


    </StyledTabbedView>
  );
}


export type UncontrolledTabbedViewProps = {
  className?: string,

  tabs: Tab[],

  initiallySelectedTab?: Tab,
  onActiveTabChanged?: (tab: Tab) => void
};

export const UncontrolledTabbedView: React.FC<UncontrolledTabbedViewProps> = ({ className, tabs, initiallySelectedTab, onActiveTabChanged }) => {
  const [activeTab, setActiveTab] = useState(initiallySelectedTab ?? tabs[0]);

  useEffect(() => {
    if (onActiveTabChanged) onActiveTabChanged(activeTab);
  }, [activeTab]);

  return (
    <TabbedView
      className={className}
      tabs={tabs}
      activeTab={activeTab}
      onActiveTabChanged={setActiveTab}
    />
  );
}

