import { createDeferred, Deferred } from "./Deferred";

export const scrollElementBy = (container: Element, options: ScrollToOptions) => {
  try {
    container.scrollBy(options);
  } catch (err) {
    container.scrollBy(options.top ?? 0, options.left ?? 0);
  }
}

export const isInViewport = (elem: Element, container: Element = document.documentElement) => {
  const b = elem.getBoundingClientRect();
  const top = container ? container.getBoundingClientRect().top : 0;
  const left = container ? container.getBoundingClientRect().left : 0;
  const bottom = container ? container.getBoundingClientRect().bottom : window.innerHeight || document.documentElement.clientHeight;
  const right = container ? container.getBoundingClientRect().right : window.innerWidth || document.documentElement.clientWidth;

  return b.top >= top
    && b.left >= left
    && b.bottom <= bottom
    && b.right <= right;
}



export const waitForElementReady = <T extends HTMLElement = HTMLElement>(selector: string, container: Element): [Deferred<T>, () => void] => {
  const d = createDeferred();

  let isCancelled = false;

  const check = () => {
    const elem = container.querySelector<T>(selector);
    if (elem) {
      d.resolve(elem);
    } else {
      if (!isCancelled) window.requestAnimationFrame(check);
      else d.reject(new Error('waitForElementReady was cancelled'));
    }
  }
  check();

  const cancel = () => isCancelled = true;

  return [d, cancel];
}


export const waitForAllElementReady = (selectors: string[], container: Element = document.documentElement, timeout: number | null = null): [Deferred<Element[]>, () => void] => {
  const d = createDeferred();

  const awaiters = selectors
    .map(selector => waitForElementReady(selector, container))


  let timeoutHandle: number | null = null;
  if (timeout) {
    timeoutHandle = setTimeout(() => {
      cancel();
    }, timeout);
  }

  Promise.all(awaiters.map(([promise]) => promise))
    .then(elems => {
      d.resolve(elems);
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    })
    .catch(err => d.reject(err));

  const cancel = () => {
    awaiters.forEach(([, cancel]) => cancel());
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
  };


  return [d, cancel];
}

export const scrollToPageTop = (containerSelector: string = '#app-root') => {
  const elem = document.querySelector(containerSelector);
  if (!elem) return;

  try {
    elem.scrollTo({ top: 0, behavior: 'smooth' });
  } catch (err) {
    // pre blink edge only accepts this form without the use of a polyfill https://github.com/iamdustan/smoothscroll
    elem.scrollTo(0, 0);
  }
}