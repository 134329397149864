import React from 'react';
import { styled } from '../../theme';
import { UsePagination } from '../../common';
import { FontAwesomeIcon, LightIcons } from '../fa-icon';


const StyledPaginationControl = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  max-width: 20rem;
  margin: 0 auto;

  padding: 1rem;

  .wrapper {
    margin: 1rem;
  }

  .page-ctrl {
    cursor: pointer;
    color: ${p => p.theme.colors.primary};
    line-height: 1.5rem;
    font-size: 1.5rem;
  }
`;

export type PaginationControlProps = {
  pagination: UsePagination
}

export const PaginationControl: React.FC<PaginationControlProps> = ({ pagination }) => {
  const { page, pageCount, gotoFirst, gotoLast, gotoNext, gotoPrevious } = pagination;

  return (
    <StyledPaginationControl>
      {/* <div className="wrapper"> */}
        <span className="page-ctrl" onClick={gotoFirst}>
          <FontAwesomeIcon icon={LightIcons.faChevronDoubleLeft} />
        </span>
        <span className="page-ctrl" onClick={gotoPrevious}>
          <FontAwesomeIcon icon={LightIcons.faChevronLeft} />
        </span>

        {page + 1}/{pageCount}

        <span className="page-ctrl" onClick={gotoNext}>
          <FontAwesomeIcon icon={LightIcons.faChevronRight} />
        </span>
        <span className="page-ctrl" onClick={gotoLast}>
          <FontAwesomeIcon icon={LightIcons.faChevronDoubleRight} />
        </span>

      {/* </div> */}
    </StyledPaginationControl>
  );
}