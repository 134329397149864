
export type Deferred<T> = {
  promise: Promise<T>
  resolve: (value?: T | PromiseLike<T>) => void | undefined
  reject: (reason?: any) => void | undefined
  didResolve: boolean
  didReject: boolean
  // didFinish: boolean
}

// export class _Deferred<T> implements Deferred<T> {

//   private _resolve: (value?: T | PromiseLike<T>) => void | undefined;
//   private _reject: (reason?: any) => void | undefined;


//   readonly promise: Promise<T> = new Promise<T>((resolve, reject) => {
//     _resolve = (value?: T | PromiseLike<T>) => { didResolve = true; return this.resolve(value); };
//     _reject = (reason?: any) => { didReject = true; return this.reject(reason); };
//   });


//   resolve: (value?: T | PromiseLike<T>) => void | undefined
//   reject: (reason?: any) => void | undefined
//   didResolve: boolean
//   didReject: boolean


// }

export const createDeferred = <T = any>(): Deferred<T> => {
  let _resolve: (value?: T | PromiseLike<T>) => void | undefined;
  let _reject: (reason?: any) => void | undefined;

  let didResolve = false;
  let didReject = false;

  const promise = new Promise<T>((resolve, reject) => {
    _resolve = (value?: T | PromiseLike<T>) => { didResolve = true; return resolve(value); };
    _reject = (reason?: any) => { didReject = true; return reject(reason); };
  });


  return { promise, resolve: _resolve!!, reject: _reject!!, didResolve, didReject }
}