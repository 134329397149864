import React from 'react';
import { useTheme } from '../../theme';

import * as _ReactSelect from 'react-select';
export const ReactSelect = _ReactSelect;

import ReactSelectComponent, { Props, OptionTypeBase } from 'react-select';

// export const SelectInput = ReactSelect;
// export default SelectInput;


export function SelectInput<T extends OptionTypeBase>(props: Props<T>) {
  const appTheme = useTheme();
  return <ReactSelectComponent
    {...props}
    menuPortalTarget={document.body}
    styles={{
      menu: (base) => ({ ...base,  zIndex: 99999999999 }),
      menuPortal: (base) => ({ ...base,  zIndex: 99999999999 }),
    }}
    theme={(theme) => ({
      ...theme,
      // @ref(https://react-select.com/styles#overriding-the-theme)
      colors: {
        ...theme.colors,
        primary: appTheme?.colors.primary
      }
    })}
  />;
}