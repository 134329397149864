import React from 'react';
import { Deferred, Result } from '../../common';
import { DialogOptions } from './Dialog';

export type DialogContextType = {
  display<T = any, E = any>(
    key: string | undefined,
    componentCreator: (dialogResult: Deferred<Result<T, E>>) => React.ReactElement,
    options: DialogOptions
  ): Promise<Result<T, E>>
};

const missingContextError = (..._args: any[]): never => {
  throw new Error('Using useDialog outside of valid context. Wrap the Component using the DialogProvider.');
}

export const DialogContext = React.createContext<DialogContextType>({
  display: missingContextError
});
DialogContext.displayName = 'DialogContext';