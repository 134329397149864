import React, { useMemo } from 'react';
import classNames from 'classnames';
import { styled } from '../../theme';

import { useCollectionState, UseCollectionStateInit, CollectionTableInstance } from './collection-state';
import { StringRecord } from './types';

const StyledCollectionTable = styled.div`
  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid black;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;

      .thead,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .thead {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .tbody {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

export type CollectionTableProps<ColData, ColExtra> =
  UseCollectionStateInit<ColData, ColExtra>

export function CollectionTable<ColData extends StringRecord, ColExtra>(props: CollectionTableProps<ColData, ColExtra>) {
  console.log('CollectionTable')
  const { tableInstance, featureFlags } = useCollectionState(props);
  console.log('CollectionTable', tableInstance)
  const { page, rows, headerGroups, getTableProps, getTableBodyProps } = tableInstance;

  const tableRowsData = page ?? rows;
  console.log(tableRowsData);

  const headerRows = useMemo(() => renderHeaderGroups(tableInstance), [headerGroups]);
  const tableRows = useMemo(() => renderTableRows(tableInstance), [tableRowsData]);
  
  return (
    <StyledCollectionTable>
      <div {...getTableProps()} className={classNames('table', { sticky: featureFlags["primary-column"] })}>
        <div className="thead">
          {headerRows}
        </div>

        <div {...getTableBodyProps()} className="tbody">
          {tableRows}
        </div>
      </div>
    </StyledCollectionTable>
  );
}

const renderHeaderGroups = (tableInstance: CollectionTableInstance) => {
  const { headerGroups } = tableInstance;
  console.log(headerGroups);

  return headerGroups.map((headerGroup) => {
    const headerCells = headerGroup.headers.map(header => {
      return (
        <div {...header.getHeaderProps()} className="th">
          {header.render('Header')}
        </div>
      );
    });

    return (
      <div {...headerGroup.getHeaderGroupProps()} className="tr">
        {headerCells}
      </div>
    );
  });
}

const renderTableRows = (tableInstance: CollectionTableInstance) => {
  const rows = tableInstance.page ?? tableInstance.rows ?? [];
  return rows.map(row => {
    tableInstance.prepareRow(row);

    const cells = row.cells.map(cell => {
      return (
        <div {...cell.getCellProps()} className="td">
          {cell.render('Cell')}
        </div>
      );
    });

    return (
      <div {...row.getRowProps()} className="tr">
        {cells}
      </div>
    );
  });
}
