import React from 'react';

export type RegisterServieWorkerResult =
  | { success: true, registrations: ServiceWorkerRegistration[] }
  | { success: false, error: { reason: any, message: React.ReactNode } }


// const workerBaseUri = () => `https://${window.location.host}`

export const registerWorkers = async (): Promise<RegisterServieWorkerResult> => {
  // const workerBase = workerBaseUri();
  try {
    const reg = await navigator.serviceWorker.register(`/service-workers/authz.conact-sw.js`, {
      scope: '/'
    });
    console.log('successfully registered service worker -> ', reg);

    navigator.serviceWorker.addEventListener('message', handleWorkerMessage);
    navigator.serviceWorker.addEventListener('controllerchange', (evt) => {
      console.log('registerWorkers::controllerchange', evt);
    })
    return { success: true, registrations: [reg] };

  } catch (error) {
    console.error('unable to install service worker', error);
    return {
      success: false, error: {
        reason: error, message: (
          <>
            <p>Für die Applikation notwendige Komponenten konnten nicht geladen werden.</p>
            <p>Bitte versuchen Sie die Seite neu zu laden.</p>
          </>
        )
      }
    };
  }
}

export type WorkerMessage =
  | { type: 'navigate-to-login' }
  | { type: 'page-reload' }


const handleWorkerMessage = (evt: MessageEvent) => {
  const msg = evt.data as WorkerMessage;
  console.log('handleWorkerMessage', evt);
  switch (msg.type) {
    case 'navigate-to-login': {
      window.location.replace('/auth/login');
      break;
    }
    case 'page-reload': {
      window.location.reload();
      break;
    }
  }
}