import * as React from 'react';
import { styled, ThemeColorName, pickColor, pickTextColor } from '../../theme';
import { isNil } from 'lodash';
import { FontAwesomeIcon, SolidIcons } from '../fa-icon';

export type CardProps = {
  /**
   * @default "primary"
   */
  colorTheme?: ThemeColorName,
  className?: string,
  bodyClassName?: string,
  boxShadow?: string,


  renderHeader?: () => React.ReactNode,
  renderFooter?: () => React.ReactNode,
}


export const SimpleCard = styled.div<{ boxShadow?: string }>`
  background-color: white;
  margin: 0.2rem auto;  
  border-radius: 4px;
  box-shadow: ${props => props.boxShadow ?? 'rgba(0, 0, 0, 0.74) 7px 4px 6px 0px'};
`;

type CardContainerProps = Omit<CardProps, 'renderHeader' | 'renderFooter'>;
const CardContainer = styled.div<CardContainerProps>`
  margin: 0.1rem auto;
  background: ${pickColor};

  border-radius: 1.5px;
  box-shadow: ${props => props.boxShadow ?? 'rgba(0, 0, 0, 0.74) 7px 4px 6px 0px'};

  &:hover > .card-header-separator {
    width: 40%;
  }
`;

const CardHeaderSeperator = styled.div`
  width           : 27%;
  height          : 3px;
  background-color: ${pickColor};
  margin-top      : 0.3rem;
  margin-right    : 1em;
  transition      : 0.5s ease all;
`;

export const CardHeader = styled.div`

`;

const CardContent = styled.div<{ headerPresent: boolean }>`
  height: ${props => props.headerPresent ? 'calc(100% - 3rem)' : '100%'};
  /* text-align: center; */
  margin: 0 0.5rem;
`;

export const CardFooter = styled.div`
  margin-top: 0.5rem;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.74);
`;


export const Card: React.FC<CardProps> = ({ renderHeader, renderFooter, children, className, bodyClassName, colorTheme = 'empty' }) => {
  const header = !isNil(renderHeader)
    ? (
      <>
        <CardHeader>{renderHeader()}</CardHeader>
        <CardHeaderSeperator className="card-header-separator" />
      </>
    ) : null;
  const footer = !isNil(renderFooter)
    ? <CardFooter>{renderFooter()}</CardFooter> : null;

  return (
    <CardContainer colorTheme={colorTheme} className={className}>
      {header}
      <CardContent className={bodyClassName} headerPresent={header !== null}>{children}</CardContent>
      {footer}
    </CardContainer>
  );
}

type TextCardProps = Omit<CardProps, 'renderHeader' | 'renderFooter'>;

const StyledTextCard = styled(Card)`
  color: ${pickTextColor};
  text-align: center;
`;

export const TextCard: React.FC<TextCardProps> = props => <StyledTextCard {...props} />

const CollapseHeader = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > :not(.collapse-indicator) {
    flex-grow: 1;
  }

  .collapse-indicator { 
    margin: 0.5rem; 
    color: ${p => p.theme.colors.primary};
  }
`;


export const ShownIndicator: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" className="collapse-indicator">
    <path fill="#000" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const HiddenIndicator: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" className="collapse-indicator">
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);


// const CollapseHeaderShownIndicator = styled((props: { isCollapsed: boolean }) => props.isCollapsed ? < HiddenIndicator /> : <ShownIndicator />)``;


export type CollapsableCardProps = CardProps & {
  /**
   * @default false
   */
  initiallyCollapsed?: boolean,
  onCollapseChanged?: (collapsed: boolean) => void,
};

export const CollapsableCard: React.FC<CollapsableCardProps> = ({ renderHeader, renderFooter, initiallyCollapsed = false, children, onCollapseChanged }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(initiallyCollapsed);
  const toggle = React.useCallback(() => setIsCollapsed(curr => !curr), [isCollapsed]);

  React.useEffect(() => {
    if (onCollapseChanged) onCollapseChanged(isCollapsed);
  }, [isCollapsed, onCollapseChanged])

  const renderCollapseHeader = () => {
    const content = renderHeader ? renderHeader() : null;

    return (
      <CollapseHeader onClick={toggle}>
        {content}
        {/* <CollapseHeaderShownIndicator isCollapsed={isCollapsed} /> */}
        <FontAwesomeIcon className="collapse-indicator" icon={isCollapsed ? SolidIcons.faPlus : SolidIcons.faMinus} />
      </CollapseHeader>
    );
  }

  return <Card renderFooter={renderFooter} renderHeader={renderCollapseHeader}>
    <div style={{ display: isCollapsed ? 'none' : 'block', width: '100%', height: '100%' }}>
      {children}
    </div>
  </Card>
};

export type LazyCollapsableCardProps = CollapsableCardProps & {
  loadContent: () => React.ReactNode
};

export const LazyCollapsableCard: React.FC<LazyCollapsableCardProps> = ({ onCollapseChanged, ...props }) => {
  const [loadedContent, setLoadedContent] = React.useState<{ content: React.ReactNode } | null>(null);

  const _onCollapseChanged = React.useCallback((collapsed) => {
    if (onCollapseChanged) onCollapseChanged(collapsed);

    // card is not collapsed & no content was loaded at this point
    if (!collapsed && !loadedContent) {
      setLoadedContent({ content: props.loadContent() });
    }
  }, [loadedContent]);

  return (
    <CollapsableCard {...props} onCollapseChanged={_onCollapseChanged}>
      {loadedContent?.content}
    </CollapsableCard>
  );
}