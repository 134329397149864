import React, { useState, useCallback } from 'react';
import { styled, ThemeColorName, pickColor } from '../../theme';
import { FontAwesomeIcon, SolidIcons, FontAwesomeIconProps } from '../fa-icon';
import { createDeferred } from '../../common';


// type HtmlInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export type InputProps = {
  /**
   * @default "primary"
   */
  colorTheme?: ThemeColorName
};

export const StyledInput = styled.div<InputProps>`
    width: 100%;


    input {
      border: 1.25px solid lightgray;
      padding: 0.5em;
      border-radius: 4px;
      line-height: 1.25rem;
      font-size: 1rem;
  
      /*width: calc(100% - 2.5px);*/
      width: 100%;
  
      background-color: white;
  
      &:focus {
        outline: none;
        border: 1.25px solid ${props => pickColor(props)};
      }
  
      &:hover {
        outline: none;
        border: 1.25px solid ${props => pickColor(props)};
      }
    }
`;

export const Input = styled.input<InputProps>`
  border: 1.25px solid lightgray;
  padding: 0.5em;
  border-radius: 4px;
  line-height: 1.25rem;
  font-size: 1rem;

  /* width: calc(100% - 1em - 2.5px); */

  background-color: white;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */

  &:focus {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }

  &:hover {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }
`;

// export const Input: React.FC<InputProps & HtmlInputProps> = (props) => (
//   <StyledInput colorTheme={props.colorTheme}>
//     <input {...props} />
//   </StyledInput>
// )


// export const InputWithIcon: React.FC = () => {

// }


export const TextAreaInput = styled.textarea<InputProps>`
    border: 1.25px solid lightgray;
    padding: 0.5em;
    border-radius: 4px;
    line-height: 1.25rem;
    font-size: 1rem;
    background-color: white;

    &:focus {
      outline: none;
      border: 1.25px solid ${props => pickColor(props)};
    }

    &:hover {
      outline: none;
      border: 1.25px solid ${props => pickColor(props)};
    }
`;


export type InputLabelProps = {
  label: string,
  className?: string,
  // children: React.ReactElement<HTMLInputElement | HTMLTextAreaElement>
}

const InputLabel = styled.label`
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: small;
  margin-bottom: 0.2em;
  color: #333333;
`;

const StyledLabeledInput = styled.div`
  & > * {
    // width: calc(100% - 1em);
    width: 100%;
  }
`;

export const LabeledInput: React.FC<InputLabelProps> = ({ label, children, className }) => {
  return (
    <StyledLabeledInput className={className}>
      <InputLabel> {label} </InputLabel>
      {children}
    </StyledLabeledInput>
  );
}



const StyledClearableInput = styled.div`
  display: inline-block;
  background-color: white;

  .input-wrapper {
    display: flex;

    border: 1.25px solid lightgray;
    padding-top: 0.5em; 
    padding-bottom: 0.5em; 
    padding-left: 1em; 
    padding-right: 1em; 
    border-radius: 4px;
    line-height: 1.25rem;
    font-size: 1rem;

    width: calc(100% - 2.5px - 2em);
  }

  input {
    border: none;
    background: none;
    outline: none;
    padding: 0 0;
    margin: 0 0;
    flex-grow: 1;
  }

  .icon {
    cursor: pointer;
    width: 1rem;
  }

  .icon:hover {
    fill: ${props => pickColor(props)};
    color: ${props => pickColor(props)};
  }

  &:focus .input-wrapper {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }

  &:hover .input-wrapper {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }
`;

export const ClearableInput: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { onClear?: () => void }> = ({ onClear, className, ...props }) => {
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const clearInput = useCallback(() => {
    if (ref) {
      ref.value = '';
      ref.focus();
      if (onClear) onClear();
    }
  }, [ref]);

  return (
    <StyledClearableInput className={className}>
      <div className="input-wrapper">
        <input {...props} ref={setRef} />

        <FontAwesomeIcon
          className="icon"
          icon={SolidIcons.faTimes}
          onClick={clearInput}
        />
      </div>
    </StyledClearableInput>
  )
};

export type InputWithIconProps = {
  className?: string,
  inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  iconProps: FontAwesomeIconProps
};

export const InputWithIcon: React.FC<InputWithIconProps> = ({ className, iconProps, inputProps }) => {

  return (
    <StyledClearableInput className={className}>
      <div className="input-wrapper">
        <input {...inputProps} />

        <FontAwesomeIcon
          className="icon"
          {...iconProps}
        />
      </div>
    </StyledClearableInput>
  )
}


const StyledFileInput = styled.div`
  display: inline-block;
  background-color: white;

  .input-wrapper {
    display: flex;

    border: 1.25px solid lightgray;
    padding-top: 0.5em; 
    padding-bottom: 0.5em; 
    padding-left: 1em; 
    padding-right: 1em; 
    border-radius: 4px;
    line-height: 1.25rem;
    font-size: 1rem;

    width: calc(100% - 2.5px - 2em);
  }

  input {
    border: none;
    background: none;
    outline: none;
    padding: 0 0;
    margin: 0 0;
    flex-grow: 1;
  }

  .icon {
    cursor: pointer;
    width: 1rem;
  }

  .icon:hover {
    fill: ${props => pickColor(props)};
    color: ${props => pickColor(props)};
  }

  &:focus .input-wrapper {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }

  &:hover .input-wrapper {
    outline: none;
    border: 1.25px solid ${props => pickColor(props)};
  }
`;

export type FileInputProps = {
  className?: string,
  contentType?: string,
  multiple?: boolean,
  onChange?: (files: File[]) => void
}

export const FileInput: React.FC<FileInputProps> = ({ className, contentType, multiple, onChange }) => {
  const [files, setFiles] = useState<File[]>([]);

  const prompt = () => {
    promptFile({ contentType, multiple })
      .then((files) => {
        if (onChange) onChange(files);
        setFiles(files);
      })
  }

  return (
    <StyledFileInput className={className} onClick={prompt}>
      <div className="input-wrapper">
        <input
          disabled
          value={files.map(file => file.name).join(', ')}
        />

        <FontAwesomeIcon
          className="icon"
          icon={SolidIcons.faUpload}
        />
      </div>
    </StyledFileInput>
  );
}

export const promptFile = (options: { contentType?: string, multiple?: boolean } = {}): Promise<File[]> => {
  const { contentType = '', multiple = false } = options;
  const d = createDeferred<File[]>();

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = contentType;
  input.multiple = multiple;

  input.onchange = () => {
    if (input.files) d.resolve(Array.from(input.files));
    else d.resolve([]);
  }

  input.click();

  return d.promise;
}