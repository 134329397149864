import { styled, ThemeColorName, pickTextColor, pickColor } from '../../theme';

export type ButtonType = 'filled' | 'outlined';


export type ButtonProps = {
  /**
   * @default "primary"
   */
  colorTheme?: ThemeColorName
  /**
   * @default "filled"
   */
  buttonType?: ButtonType,

  borderSize?: string
}


// border: ${props => props.buttonType === 'outlined' ? `2px solid ${pickColor(props)}` : 0};

export const Button = styled.button<ButtonProps>`
    background-color: ${props => props.buttonType === 'filled' ? pickColor(props) : props.theme.colors.empty};
    color: ${props => props.buttonType === 'filled' ? pickTextColor(props) : pickColor(props)};
    
    border-radius: 3px;
    border: ${props => props.buttonType === 'outlined' ? props.borderSize ?? '2px' : '0px'} solid ${pickColor};

    padding: .5em;
    text-decoration: none;
    clear: both;
    margin: auto;
    outline: none;
    transition: all 0.3s ease;
    user-select: none;
    text-align: center;

    &:disabled {
      background-color: ${props => props.buttonType === 'outlined' ? 'lightgray' : (props.colorTheme === 'primary' ? props.theme.colors.disabledPrimary : undefined)};
      color: ${props => props.buttonType === 'outlined' ? 'lightgray' : undefined};
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      box-shadow: 2px 2px 5px 0px gray;
      background-color: ${props => props.buttonType === 'outlined' ? pickColor(props) : (props.colorTheme === 'primary' ? props.theme.colors.hoverPrimary : undefined)};
      color: ${props => props.buttonType === 'outlined' ? pickTextColor(props) : undefined};
    }
`;

Button.defaultProps = {
  buttonType: 'filled'
};