import React, { useCallback } from 'react';
import { styled } from '../../theme';
import { useHistory } from 'react-router-dom';

export const PageTitle = styled.h1`
  font-size: ${p => p.theme.text.sizes.extraLarge};
`;

export type BackLinkProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export const BackLink: React.FC<BackLinkProps> = (props) => {
  const history = useHistory();
  const onClick = useCallback((evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.onClick) {
      // click is handled by caller, just pass it along
      evt.persist();
      props.onClick(evt);
    } else if (props.href) {
      // use react-router history api to perform the navigation
      evt.preventDefault();
      history.push(props.href);
    }
    // href is undefined -> use default behaviour, which should be a noop
  }, [props.href, props.onClick]);


  const aProps = Object.assign({}, props, { onClick });

  return <a {...aProps} />;
}