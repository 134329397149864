import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, } from 'react-router-dom';
import loadable from '@loadable/component';
import { AuthContext, AuthContextType } from '../auth/AuthContext';
import { isAuthenticated } from '../auth/api';
import { GlobalLoadingOverlayContext } from '@webapp/explorer/app-shell/components/global-loading-screen/GlobalLoadingOverlay';



export const FallbackLoadingScreen: React.FC<{message: string}> = ({message}) => {
  const overlayCtx = useContext(GlobalLoadingOverlayContext);
  useEffect(() => {
    overlayCtx.setMessage(message);
    overlayCtx.show();

    return () => overlayCtx.hide();
  }, [message]);
  return null;
}

const LoadableAuthApplication = loadable(
  () => /* webpackChunkName: "auth-app" */ import('../auth/AuthApplication'),
  { fallback: <FallbackLoadingScreen message="Applikation wird geladen..." /> }
);
LoadableAuthApplication.displayName = 'LoadableAuthApplication';

const LoadableExplorerApplication = loadable(
  () => /* webpackChunkName: "explorer-app" */ import('../explorer/ExplorerApplication'),
  { fallback: <FallbackLoadingScreen message="Applikation wird geladen..." /> }
);
LoadableExplorerApplication.displayName = 'LoadableExplorerApplication';

export const MainRouter: React.FC = () => {
  const [authCtx, setAuthCtx] = React.useState<Omit<AuthContextType, 'update'>>({
    status: isAuthenticated() ? 'authenticated' : 'unauthorized'
  });

  const updateAuthCtx = React.useCallback((setter: AuthContextType | ((prev: AuthContextType) => AuthContextType)) => {
    setAuthCtx(setter);
  }, [authCtx]);

  return (
    <AuthContext.Provider value={{ ...authCtx, update: updateAuthCtx }}>
      <Router>
        <Switch>
          <Route
            path="/auth"
            component={LoadableAuthApplication}
          />

          <Route
            component={
              authCtx.status === 'authenticated'
                ? LoadableExplorerApplication
                : () => <Redirect to={{ pathname: "/auth/login" }} />}
          />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

