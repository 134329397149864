
export type BreakpointName =
  | 'small'
  | 'medium'
  | 'large'
  | 'extraLarge'

export type BreakpointSpec = {
  small: number,
  medium: number,
  large: number,
  extraLarge: number,
}


export const Breakpoints: BreakpointSpec = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,

}

// const minBreak = (breakpoint: BreakpointName) => `(min-width: ${Breakpoints[breakpoint]}px)`;
// export const mediaMin = (breakpoint: BreakpointName) => `@media ${minBreak(breakpoint)}`;

// const maxBreak = (breakpoint: BreakpointName) => `(max-width: ${Breakpoints[breakpoint] - 0.02}px)`;
// export const mediaMax = (breakpoint: BreakpointName) => `@media ${maxBreak(breakpoint)}`;
// export const mediaBetween = (min: BreakpointName, max: BreakpointName) =>
//   `@media ${minBreak(min)} and ${maxBreak(max)}`;

// export const mediaOnly = (breakpoint: BreakpointName) => {
//   switch (breakpoint) {
//     case 'small': return mediaMax('small');
//     case 'medium': return mediaBetween('small', 'medium');
//     case 'large': return mediaBetween('medium', 'large');
//     case 'extraLarge': return mediaMin('small');
//   }
// }

export const mediaMin = (breakpoint: BreakpointName) => `(min-width: ${Breakpoints[breakpoint]}px)`;
export const mediaMax = (breakpoint: BreakpointName) => `(max-width: ${Breakpoints[breakpoint] - 0.02}px)`;
export const mediaBetween = (min: BreakpointName, max: BreakpointName) => `${mediaMin(min)} and ${mediaMax(max)}`;

export const mediaOnly = (breakpoint: BreakpointName) => {
  switch (breakpoint) {
    case 'small': return mediaMax('small');
    case 'medium': return mediaBetween('small', 'medium');
    case 'large': return mediaBetween('medium', 'large');
    case 'extraLarge': return mediaMin('small');
  }
}

