
export type PromiseExecutor<T> = (
  resolve: (value?: T | PromiseLike<T>) => void,
  reject: (reason?: any) => void
) => void;

export class LazyPromise<T = any> extends Promise<T> {

  private _wrappedPromise: Promise<T> | undefined;

  constructor(private readonly executor: PromiseExecutor<T>) {
    super((resolve) => resolve());
  }

  then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null) {
    this._wrappedPromise = this._wrappedPromise ?? new Promise(this.executor);
    return this._wrappedPromise.then(onfulfilled, onrejected);
  }

  catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
    this._wrappedPromise = this._wrappedPromise ?? new Promise(this.executor);
    return this._wrappedPromise.then(onrejected);
  }

}
