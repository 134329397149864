import React from 'react';
import { styled, ThemeColorName, pickColor } from '../../theme';

//     color: ${props => props.buttonType === 'filled' ? pickTextColor(props) : pickColor(props)};

const StyledZoneButton = styled.svg`
  
  width: 100%;
  height: 100%;


  &:hover {
    cursor: pointer;

  }
    .z {
      opacity: 0.65;
    }

  .z {
    fill: ${pickColor};
    transition: all 0.8s ease;
  }

  ellipse {
    transition: all 1s ease;
  }

  @media only screen and (max-width: 600px) {
    rect.z {
      opacity: 0.65;
    }
  }
`;
StyledZoneButton.displayName = 'StyledZoneButton';

type ZoneButtonProps = React.SVGProps<SVGSVGElement> & { colorTheme?: ThemeColorName };

export const ZoneButton: React.FC<ZoneButtonProps> = (props) => (
  <svg viewBox="0 0 120 75" stroke="null" {...props}>
    <StyledZoneButton>
      <g stroke="null">
        <ellipse stroke="#00c1b5" ry="59" rx="59" id="svg_12" cy="37.499999" cx="59.999997" fillOpacity="0" strokeWidth="0" fill="#ffffff" />
        <ellipse stroke="#00c1b5" ry="50" rx="50" id="svg_26" cy="37.5" cx="60.000002" fillOpacity="0" strokeWidth="0" fill="#ffffff" />
        <rect stroke="null" id="svg_10" height="10" width="10" y="52.93581" x="55.034847" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_11" height="10" width="10" y="52.93581" x="75.034847" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_17" height="10" width="10" y="52.93581" x="35.034847" fill="#fff" />
        <rect stroke="null" id="svg_18" height="10" width="10" y="32.93581" x="55.406839" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_19" height="10" width="10" y="32.93581" x="75.034847" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_20" height="10" width="10" y="32.93581" x="35.034847" fill="#fff" />
        <rect stroke="null" id="svg_21" height="10" width="10" y="12.93581" x="55.034847" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_22" height="10" width="10" y="12.93581" x="75.034847" fill="#fff" />
        <rect stroke="null" strokeWidth="0" id="svg_23" height="10" width="10" y="12.93581" x="35.034847" fill="#fff" />
        <rect className="z" stroke="null" fill="#09c1b5" strokeWidth="NaN" strokeOpacity="null" x="35.058291" y="52.936615" width="49.883207" height="10.02944" id="svg_24" opacity="0" />
        <rect className="z" transform="rotate(-45 60.300765991210916,37.71281433105469) " stroke="null" fill="#09c1b5" strokeWidth="NaN" strokeOpacity="null" x="32.00699" y="30.817017" width="56.58756" height="13.791585" id="svg_25" opacity="0" />
        <rect className="z" stroke="null" fill="#09c1b5" strokeWidth="NaN" strokeOpacity="null" x="35.119161" y="12.781969" width="49.95007" height="10.119438" id="svg_13" opacity="0" />
      </g>
    </StyledZoneButton>
  </svg>
);

ZoneButton.displayName = 'ZoneButton';