import { useState, useMemo, useCallback } from "react";

export type UsePaginationOptions = {
  totalSize: number,
  itemsPerPage: number,
  initialPage?: number
}

export type UsePagination = Omit<UsePaginationOptions, 'initialPage'> & {
  paginationNeeded: boolean,
  page: number,
  pageCount: number,
  canNext: boolean,
  canPrevious: boolean,
  goto: (page: number) => void,
  gotoNext: () => void,
  gotoPrevious: () => void,
  gotoLast: () => void,
  gotoFirst: () => void,
}

export const usePagination = ({ totalSize, itemsPerPage, initialPage = 0 }: UsePaginationOptions): UsePagination => {
  const [page, setPage] = useState(initialPage)

  const pageCount = useMemo(() => Math.ceil(totalSize / itemsPerPage), [totalSize, itemsPerPage]);

  const safeGoto = useCallback((pageIndex: number) => {
    if (pageIndex >= 0 && pageIndex < pageCount)
      setPage(pageIndex);
  }, [page, pageCount]);

  return {
    totalSize,
    itemsPerPage,

    paginationNeeded: totalSize > itemsPerPage,

    page,
    pageCount,

    canNext: page < pageCount,
    canPrevious: page > 0,

    goto: safeGoto,
    gotoNext: () => safeGoto(page + 1),
    gotoPrevious: () => safeGoto(page - 1),

    gotoLast: () => setPage(pageCount - 1),
    gotoFirst: () => setPage(0),
  };
}

export function applyPagination<T>(data: T[], pagination: UsePagination) {
  const start = pagination.page * pagination.itemsPerPage;
  const end = start + pagination.itemsPerPage;
  return data.slice(start, end);
}