// import React from 'react';
import { styled, ThemeColorName, pickColor as pickThemeColor, keyframes, Theme } from '../../theme';


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export type LoadingSpinnerProps = {
  colorTheme?: ThemeColorName,
  theme: Theme,
  color?: string
};

const pickColor = (props: LoadingSpinnerProps) => {
  if (props.colorTheme) {
    return pickThemeColor(props);
  } else if (props.color) {
    return props.color;
  } else {
    return props.theme.colors.primary;
  }
}

export const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  border: 16px solid #727877;
  border-radius: 50%;
  border-top: 16px solid ${pickColor};
  border-bottom: 16px solid ${pickColor};
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s cubic-bezier(0.01, 0.79, 1, 0.21) infinite;

  margin: 0 auto;
`;
