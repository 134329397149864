import Fuse from 'fuse.js';
import { useState, useMemo } from 'react';

export type UseTextSearchOptions<D extends object> = {
  initialSearchValue?: string,
  data: D[],
  searchOptions?: Fuse.IFuseOptions<D>
}

export type UseTextSearch<D extends object> = {
  searchValue: string,
  setSearchValue: (value: string) => void,
  searcher: Fuse<D, Fuse.IFuseOptions<D>>,
  searchResults: D[]
}

export const useTextSearch = <D extends object>({ initialSearchValue, data, searchOptions }: UseTextSearchOptions<D>): UseTextSearch<D> => {
  const [searchValue, setSearchValue] = useState(initialSearchValue ?? '');
  const searcher = useMemo(() => new Fuse(data, searchOptions), [data, searchOptions]);

  const searchResults = useMemo(() => {
    if (searchValue !== '')
      return searcher.search(searchValue).map(res => res.item);
    else
      return data;
  }, [searcher, searchValue, data]);

  return {
    searchValue,
    setSearchValue,
    searcher,
    searchResults
  }
}


