import * as React from 'react';
import { styled, ThemeColorName } from '../../theme';
import { FontAwesomeIconProps, SolidIcons, FontAwesomeIcon } from '../fa-icon';
import { useCallback, ReactNode, useEffect, useState } from 'react';
import { Deferred, useArray } from '../../common';


export const ListItem = styled.li`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;


export type ListProps = {
  /**
   * @default "primary"
   */
  colorTheme?: ThemeColorName,

  // children?: React.ReactElement<HTMLLIElement> | React.ReactElement<HTMLLIElement>[]
}

export const List = styled.ul<ListProps>`
  text-align: left;
  list-style: none;
  padding: 0;  
  margin: 0;
  background-color: white;

  li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  
  li:not(:last-child) {
    border-bottom-color: gray;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
`;


export type LinkListData = {
  content: React.ReactNode,
  link: string,
  key: string,
  overrideContentIconProps?: FontAwesomeIconProps,
  overrideLinkIconProps?: FontAwesomeIconProps,
}

export type LinkListProps = {
  className?: string,
  contentIconProps?: FontAwesomeIconProps,
  linkIconProps?: FontAwesomeIconProps,
  onLinkClick: (link: string, key: string) => void,
  onContentIconClick?: (link: string, key: string) => void,
  data: LinkListData[]
}

const StyledLinkListItem = styled(ListItem)`
  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LinkList: React.FC<LinkListProps> = ({ className, data, onLinkClick, onContentIconClick = onLinkClick, contentIconProps, linkIconProps = { icon: SolidIcons.faArrowRight } }) => {
  const linkClicked = useCallback((link: string, key: string, evt: React.MouseEvent) => {
    evt.preventDefault();
    onLinkClick(link, key);
  }, [onLinkClick]);

  const iconClicked = useCallback((link: string, key: string, evt: React.MouseEvent) => {
    evt.preventDefault();
    onContentIconClick(link, key);
  }, [onLinkClick]);

  return (
    <List className={className}>
      {
        data.map(({ content, link, key, overrideContentIconProps, overrideLinkIconProps }) => {
          const itemContentIconProps = overrideContentIconProps ?? contentIconProps;
          const itemLinkIconProps = overrideLinkIconProps ?? linkIconProps;

          return (
            <StyledLinkListItem key={key}>
              <a href={link} onClick={linkClicked.bind(null, link, key)}>
                {itemContentIconProps ? <FontAwesomeIcon {...itemContentIconProps} onClick={iconClicked.bind(null, link, key)} /> : null}
                {content}
                <FontAwesomeIcon {...itemLinkIconProps} />
              </a>
            </StyledLinkListItem>
          );
        })
      }
    </List>
  );
}


export type DeferredListData<D = any> = {
  key: string,
  deferred: Deferred<D>
}

export type DeferredListProps<D = any> = {
  className?: string,
  renderRow: (value: D, key: string) => ReactNode,
  deferredItems: DeferredListData<D>[]
}

export function DeferredList<D = any>({ className, deferredItems, renderRow }: DeferredListProps<D>) {
  const [items, { clear, push }] = useArray<{ key: string, node: ReactNode }>([]);

  useEffect(() => {
    clear();

    for (const { key, deferred } of deferredItems) {
      deferred.promise.then(value => push({ key, node: renderRow(value, key) }));
    }
  }, [deferredItems, renderRow]);

  return (
    <List className={className}>
      {
        items.map(item => <ListItem key={item.key}>{item.node}</ListItem>)
      }
    </List>
  );
}


const StyledExpandableListItem = styled.li`

  .label-container {
    display: flex;
    cursor: pointer;
  }

  .label { flex-grow: 1; }
`;

export type ExpandableListItemProps = {
  className?: string,
  initiallyExpanded?: boolean,
  label: React.ReactNode,
  renderContent: () => React.ReactNode
}

export const ExpandableListItem: React.FC<ExpandableListItemProps> = ({
  className,
  initiallyExpanded,
  label,
  renderContent
}) => {
  const [isExpanded, setIsExpanded] = useState(!!initiallyExpanded);

  return (
    <StyledExpandableListItem className={className}>
      <div className="label-container" onClick={() => setIsExpanded(prev => !prev)}>
        <span className="label">{label}</span>
        <FontAwesomeIcon className="icon" icon={isExpanded ? SolidIcons.faMinus : SolidIcons.faPlus} />
      </div>
      {
        isExpanded ? renderContent() : null
      }

    </StyledExpandableListItem>
  );
}