import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';


export type SearchQueryActions = {
  set: (data: object) => void
  merge: (data: object) => void
}

export const useSearchQuery = (): [object, SearchQueryActions] => {
  const location = useLocation();
  const history = useHistory();

  const parse = useCallback(() => {
    try {
      return JSON.parse(decodeURIComponent(location.search));
    } catch (err) {
      return {};
    }
  }, [location]);


  const set = useCallback((data: object) => history.push({ search: encodeURIComponent(JSON.stringify(data)) }), [history]);
  const merge = useCallback((data: object) => history.push({ search: encodeURIComponent(JSON.stringify(Object.assign({}, parse(), data))) }), [history]);

  return [
    parse(),
    { set, merge }
  ]
}


export const useURLSearchParams = () => new URLSearchParams(useLocation().search)