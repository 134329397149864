import { Theme, ThemeColors, ThemeSizes } from "./spec";


const Colors: ThemeColors = {
  primary: '#96BD0D',
  // primary: '#FF68E9',
  secondary: '#F1F1F1',
  success: 'green',
  danger: '#D60000',
  warning: 'yellow',
  info: 'cyan',
  empty: 'white',
}

const TextColors: ThemeColors = {
  primary: 'white',
  secondary: 'black',
  success: 'black',
  danger: 'white',
  warning: 'black',
  info: 'black',
  empty: 'black'
}

const Sizes: ThemeSizes = {
  small: '12px',
  medium: '16px',
  large: '20px',
  extraLarge: '24px'
}

export const DefaultTheme: Theme = {
  bgColor: '#F1F1F1',
  colors: {
    ...Colors,
    hoverPrimary: '#A5CF0E',
    disabledPrimary: '#96BD0D26'
  },
  text: {
    colors: TextColors,
    sizes: Sizes,
    // defaultFont: '1.156em/1.3 "Fira Sans", Gill Sans, My Gill Sans, sans-serif'
    defaultFont: '1rem "Roboto", Gill Sans, My Gill Sans, sans-serif'
    // defaultFont: '1.156em/1.5 "Roboto", Gill Sans, My Gill Sans, sans-serif'
  }
}