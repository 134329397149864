import React from 'react';
import { styled } from '../../theme';


const SytledConActLogo = styled.svg`
  .primary{ fill: ${props => props.theme.colors.primary}; }
  .st1{ fill:#787878; }
`

export const ConActLogo: React.FC<{ className?: string }> = (props) => (
  <SytledConActLogo viewBox="0 0 598.54998 238.17998" {...props}>
    <g transform="translate(-112.81,-175.78)" id="g855">
      <path
        className="primary"
        d="m 112.81,258.83 c 0,-42.52 0,-83.05 55.45,-83.05 34.28,0 48.08,18.93 46.75,53.43 h -32.96 c 0,-21.37 -3.79,-29.39 -13.8,-29.39 -18.93,0 -21.37,17.81 -21.37,59 0,41.19 2.45,59 21.37,59 15.57,0 14.9,-20.04 15.35,-32.73 h 33.17 c 0,42.97 -17.15,56.78 -48.53,56.78 -55.43,0.01 -55.43,-40.96 -55.43,-83.04 z"
        id="path4" />
      <path
        className="primary"
        d="m 228.24,281.54 c 0,-30.72 4.46,-57 45.86,-57 41.4,0 45.86,26.28 45.86,57 0,35.18 -5.35,59.9 -45.86,59.9 -40.51,0 -45.86,-24.73 -45.86,-59.9 z m 60.34,-1.78 c 0,-23.38 -1.11,-35.18 -14.47,-35.18 -13.36,0 -14.47,11.8 -14.47,35.18 0,34.28 2.88,41.63 14.47,41.63 11.58,0 14.47,-7.35 14.47,-41.63 z"
        id="path6" />
      <path
        className="primary"
        d="m 365.06,238.13 h 0.45 c 3.12,-5.12 6.89,-8.47 11.13,-10.47 4.44,-2.22 9.57,-3.12 15.35,-3.12 14.92,0 28.06,8.91 28.06,24.49 v 90.17 h -31.4 v -77.48 c 0,-10.02 -1.78,-17.81 -11.8,-17.81 -10.02,0 -11.8,7.8 -11.8,17.81 v 77.48 h -31.4 V 226.77 h 31.4 v 11.36 z"
        id="path8" />
      <path
        className="st1"
        d="M 547.28,339.2 511.61,191.91 h -42.03 l -36.82,147.29 3.11,-0.01 v 0.01 l 27.95,-0.05 4.74,-20.86 h 40.96 l 4.87,20.97 h 1.4 v 0 z m -73.01,-46.88 13.96,-63.15 h 0.43 l 14.77,63.15 z"
        id="path10" />
      <path
        className="st1"
        d="m 612.42,266.84 c 0,-16.7 -4.24,-22.26 -14.03,-22.26 -13.36,0 -14.47,11.8 -14.47,35.18 0,34.28 2.88,41.63 14.47,41.63 9.79,0 14.47,-8.91 14.47,-23.6 h 31.4 c 0,30.5 -16.48,43.64 -45.86,43.64 -40.52,0 -45.86,-24.72 -45.86,-59.9 0,-30.72 4.46,-57 45.86,-57 28.95,0 45.41,12.92 45.41,42.3 h -31.39 z"
        id="path12" />
      <path
        className="st1"
        d="m 651.01,226.77 h 12.69 v -17.81 l 31.4,-14.02 v 31.83 h 16.26 v 19.37 H 695.1 v 60.11 c 0,8.46 -0.22,14.02 10.02,14.02 1.99,0 4.01,0 5.57,-0.44 v 19.37 c -4.24,0.44 -8.68,1.11 -16.26,1.11 -27.16,0 -30.73,-18.04 -30.73,-24.94 v -69.24 h -12.69 z"
        id="path14" />
      <g id="g26">
        <rect
          x="512.21002"
          y="403.91"
          className="primary"
          width="8.1999998"
          height="8.7299995"
          id="rect16" />
        <polygon
          className="primary"
          points="564.91,412.64 564.91,406.65 539.72,406.65 564.8,372.86 564.91,372.71 564.91,367.11 531.59,367.11 531.59,373.09 556.95,373.09 531.59,407.02 531.59,412.64 "
          id="polygon18" />
        <path
          className="primary"
          d="m 592.49,365.78 c -14.61,0 -21.26,12.49 -21.26,24.09 0,11.6 6.65,24.09 21.26,24.09 14.61,0 21.26,-12.49 21.26,-24.09 0,-11.6 -6.65,-24.09 -21.26,-24.09 z m 0,42.2 c -9.89,0 -14.39,-9.38 -14.39,-18.11 0,-8.73 4.5,-18.11 14.39,-18.11 9.89,0 14.39,9.38 14.39,18.11 0,8.73 -4.5,18.11 -14.39,18.11 z"
          id="path20" />
        <path
          className="primary"
          d="m 643.55,365.78 c -5.71,0 -10.47,2.48 -13.56,7.02 l -0.23,-5.69 h -6.62 l 0.14,2.25 c 0.16,2.57 0.34,5.49 0.34,8.31 v 34.97 h 6.43 v -26.13 c 0,-6.8 3.19,-14.74 12.18,-14.74 6.96,0 11.12,4.35 11.12,11.65 v 29.22 h 6.43 V 384.3 c -0.01,-6.92 -2.12,-18.52 -16.23,-18.52 z"
          id="path22" />
        <path
          className="primary"
          d="m 711.36,391.11 c 0,-16.34 -7.39,-25.33 -20.82,-25.33 -14,0 -20.38,12.49 -20.38,24.09 0,11.99 6.19,24.09 20.02,24.09 11.11,0 17.86,-5.2 20.62,-15.9 l 0.18,-0.7 h -6.75 l -0.1,0.44 c -1.3,5.99 -6.35,10.18 -12.28,10.18 -12.63,0 -14.6,-10.97 -14.81,-16.31 h 34.3 v -0.56 z m -34.09,-5.43 c 0.78,-6.56 4.7,-13.92 13.35,-13.92 7.96,0 12.97,5.06 13.81,13.92 z"
          id="path24" />
      </g>
    </g>
  </SytledConActLogo >
);

ConActLogo.displayName = 'ConActLogo';
