import React from "react";
import { DialogOptions, useDialog, DialogFooter, DialogCard, DialogHeader, DialogContent } from "./Dialog";
import { styled, ThemeColorName } from '../../theme';
import { Button } from '../button';
import { Deferred, Result, ok } from "../../common";

const DEFAULT_OPTIONS: DialogOptions<boolean> = {
  isDismissable: true,
  dismissValue: false
}

const ConfirmDialog = styled.div`

  margin: 0 auto;
  margin-top: 5rem;

  max-width: 400px;

  .card-content {
    padding: 1rem;
  }

  .buttons {
    display: flex;
    /* align-items: flex-end; */
    justify-content: flex-end;
    padding: 0.5rem;
  }

  ${DialogFooter} > ${Button} {
    margin-right: 0.5rem;
  }
`;

export type ConfirmDialogOptions = {
  title: string,
  okColorTheme?: ThemeColorName,
  okText?: string,
  cancelText?: string,
  okTimeoutSeconds?: number,
  renderContent: () => React.ReactElement<any>,
};

type ConfirmDialogFooterProps = Omit<ConfirmDialogOptions, 'title' | 'renderContent'>

const ConfirmDialogFooter: React.FC<ConfirmDialogFooterProps & { dialogResult: Deferred<Result<boolean, void>> }> = ({
  okText = 'Ok',
  okColorTheme,
  cancelText = 'Abbrechen',
  okTimeoutSeconds = -1,
  dialogResult
}) => {
  const [okTimeout, setOkTimeout] = React.useState(okTimeoutSeconds);

  React.useEffect(() => {
    let timeoutHandle: number | undefined = undefined
    // console.log(okTimeout)
    if (okTimeout > 0) {
      timeoutHandle = setTimeout(() => {
        setOkTimeout(prev => prev!! - 1);
      }, 1000);
    }

    return () => { if (timeoutHandle) clearTimeout(timeoutHandle); }
  }, [okTimeout]);

  const okDisabled = okTimeout > 0;
  const okCountdownText = okDisabled ? `(${okTimeout}) ` : null;
  console.log(okCountdownText)

  return (
    <DialogFooter>
      <Button
        colorTheme={okColorTheme}
        disabled={okDisabled}
        onClick={() => dialogResult.resolve(ok(true))}
      >
        {okCountdownText}{okText}
      </Button>

      <Button colorTheme="secondary" onClick={() => dialogResult.resolve(ok(false))}>
        {cancelText}
      </Button>
    </DialogFooter>
  );
}

export function useConfirmDialog(options: ConfirmDialogOptions) {
  // const renderHeader = React.useCallback(() => <h1>{options.title}</h1>, [options.title]);
  // const renderFooter = React.useCallback(() => <ConfirmDialogFooter {...options} />, [options]);

  const contentCreator = React.useCallback((dialogResult: Deferred<Result<boolean, void>>) => {
    return (
      <ConfirmDialog>
        <DialogCard>
          <DialogHeader>
            <h1>{options.title}</h1>
          </DialogHeader>

          <DialogContent>
            {options.renderContent()}
          </DialogContent>

          <ConfirmDialogFooter {...options} dialogResult={dialogResult} />
        </DialogCard>
      </ConfirmDialog>
    );
  }, []);

  return useDialog<boolean, void>(
    contentCreator,
    DEFAULT_OPTIONS
  );
}