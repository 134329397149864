import { useState, useCallback, useMemo, Dispatch, SetStateAction } from 'react';


// @ref(https://stackoverflow.com/a/5306832)
export const array_move = <T = any>(arr: Array<T | undefined>, oldIndex: number, newIndex: number) => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

export type ArrayActions<T> = {
  setValue: Dispatch<SetStateAction<T[]>>,
  push: (value: T) => void,
  pop: () => T | undefined,
  clear: () => void,
  removeIndex: (index: number) => T | undefined,
  move: (from: number, to: number) => void
}

export const useArray = <T = any>(initialValue: T[] = []): [T[], ArrayActions<T>] => {
  const [value, setValue] = useState(initialValue);

  const push = useCallback((val: T | T[]) => setValue(prev => [...prev, ...(Array.isArray(val) ? val : [val])]), []);

  const pop = useCallback(() => {
    let retVal: T | undefined = undefined;
    setValue(prev => {
      retVal = prev[prev.length - 1];
      return prev.slice(0, -1);
    })
    return retVal;
  }, []);

  const clear = useCallback(() => setValue(() => []), []);

  const removeIndex = useCallback((index: number) => {
    let retVal: T | undefined = undefined;
    setValue(prev => {
      const next = [...prev];
      [retVal] = next.splice(index, 1);
      return next;
    });
    return retVal;
  }, []);


  const move = useCallback((oldIndex: number, newIndex: number) => setValue(prev => {
    const next = [...prev];
    array_move(next, oldIndex, newIndex)
    return next;
  }), []);

  const actions = useMemo(() => {
    return {
      setValue,
      push,
      pop,
      clear,
      removeIndex,
      move
    };
  }, []);

  return [
    value,
    actions
  ]
}

