import { useState } from 'react';


export const Counter = (initialValue: number = 0, step: number = 1) => {
  let value = initialValue;
  return () => {
    const retVal = value;
    value += step;
    return retVal;
  }
}


export const useCounter = (initialValue: number = 0, step: number = 1) => {
  const [count, setCount] = useState(initialValue);

  return {
    count,
    setCount,
    inc: () => setCount(c => c + step),
    dec: () => setCount(c => c - step)
  };
}