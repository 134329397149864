import { useState } from 'react';

export type Queue<T> = T[];

export const enqueue = <T>(queue: Queue<T>, value: T) => [...queue, value];
export const add = enqueue;

export const dequeue = <T>(queue: Queue<T>) => queue.shift();
export const remove = dequeue;

export const first = <T>(queue: Queue<T>) => queue[0];
export const last = <T>(queue: Queue<T>) => queue[queue.length - 1];

export type QueueActions<T> = {
  enqueue: (value: T) => void,
  dequeue: () => T | undefined,
  first: () => T | undefined,
  last: () => T | undefined
}

export const useQueue = <T>(initial: Queue<T> = []): [Queue<T>, QueueActions<T>] => {
  const [queue, setQueue] = useState<Queue<T>>(initial);

  return [
    queue,
    {
      enqueue: (value: T) => setQueue(enqueue(queue, value)),
      dequeue: () => {
        let result: T | undefined = undefined;
        setQueue(([first, ...rest]) => {
          result = first;
          return rest;
        })
        return result;
      },
      first: () => first(queue),
      last: () => last(queue)
    }
  ]
}
