import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Result, Deferred, createDeferred, Counter, useQueue, ok } from '../../common';
import { styled } from '../../theme';
import { DialogOptions, isDismissableDialogOptions } from './Dialog';
import { DialogContextType, DialogContext } from './DialogContext';



type DialogData = {
  key: string,
  componentCreator: (dialogResult: Deferred<Result>) => React.ReactElement,
  result: Deferred<Result<any, any>>,
  options: DialogOptions
};

const DialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 999;
  width: 100vw;
  height: 100vh;

  background: #99999999;

  /* overflow: auto; */

  & > * {
    z-index: 1000;
  }
`;

type DialogPortalProps = {
  dialog?: DialogData,
  containerElement?: Element
};

export const DialogPortal = React.memo<DialogPortalProps>(({
  containerElement = document.body,
  dialog
}) => {

  const backdropClicked = React.useCallback(() => {
    if (dialog && isDismissableDialogOptions(dialog.options)) {
      dialog.result.resolve(ok(dialog.options.dismissValue));
    }
  }, [dialog]);

  const escapeListener = React.useCallback((evt?: KeyboardEvent) => {
    if (!(dialog && isDismissableDialogOptions(dialog.options))) return;

    const event = evt ?? (window.event as KeyboardEvent);
    if (('key' in event && ['Escape', 'Esc'].includes(event.key)) || event.keyCode === 27) {
      dialog.result.resolve(ok(dialog.options.dismissValue));
    }

  }, [dialog]);

  React.useEffect(() => {
    window.document.body.addEventListener('keydown', escapeListener);

    return () => window.document.body.removeEventListener('keydown', escapeListener);
  }, [dialog]);

  const eatClicks = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
  }

  const root = React.useMemo(() => {
    if (dialog) {
      return (
        <DialogBackdrop onClick={backdropClicked}>
          <div onClick={eatClicks}>
            {dialog.componentCreator(dialog.result)}
          </div>
        </DialogBackdrop>
      )
    } else {
      return null;
    }
  }, [dialog]);


  return ReactDOM.createPortal(root, containerElement);
});



type DialogManagerProps = {
  containerElement?: Element
};

const dialogKeyGen = Counter();

export const DialogManager: React.FC<DialogManagerProps> = (props) => {
  const [dialogs, dialogsActions] = useQueue<DialogData>();
  const [activeDialog, setActiveDialog] = React.useState<DialogData | undefined>(undefined);

  async function display<T = any, E = any>(
    key: string | undefined,
    componentCreator: (dialogResult: Deferred<Result<T, E>>) => React.ReactElement,
    options: DialogOptions
  ): Promise<Result<any, any>> {

    const dialog = {
      key: key ?? dialogKeyGen().toString(),
      result: createDeferred<Result<T, E>>(),
      componentCreator,
      options,
    }

    dialogsActions.enqueue(dialog);

    if (!activeDialog) { setActiveDialog(dialog); }

    return dialog.result.promise;
  }


  useEffect(() => {
    if (activeDialog) {
      activeDialog.result.promise.finally(() => {
        dialogsActions.dequeue();
        setActiveDialog(undefined);
      });
    } else {
      setActiveDialog(dialogs[0]);
    }
  }, [activeDialog]);


  const context: DialogContextType = { display };

  return (
    <DialogContext.Provider value={context}>
      <>
        {props.children}

        <DialogPortal
          dialog={activeDialog}
          {...props}
        />
      </>
    </DialogContext.Provider>
  );
}