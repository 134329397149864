import React, { useState, useEffect } from 'react';
import { styled, ThemeColorName, pickColor, pickTextColor } from '../../theme';
import { FontAwesomeIcon, SolidIcons } from '../fa-icon';
import classNames from 'classnames';
import { isBoolean } from 'lodash';

// @ref(https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd)


export type CheckboxProps = {
  className?: string,
  checked: boolean,
  colorTheme?: ThemeColorName
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

// const StyledCheckbox = styled.div<{ checked: boolean }>`
const StyledCheckbox = styled.div`
  display: inline-block;
 
  .checkbox-border {
    border: 1.5px solid gray;
    border-radius: 3px;
    
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
    
  &.checked .checkbox-border {
    background-color: ${p => pickColor(p)};
    border-color: ${p => pickColor(p)};
  }
  

  .hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .icon { display: none; }

  &.checked .icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    fill: ${p => pickTextColor(p)};
    color: ${p => pickTextColor(p)};
  }
`;

export const Checkbox: React.FC<CheckboxProps> = ({ className, checked, ...props }) => {
  return (
    <StyledCheckbox className={classNames(className, { checked })} >
      <div className="checkbox-border">
        <input className="hidden-checkbox" {...props} type="checkbox" />
        <FontAwesomeIcon icon={SolidIcons.faCheck} className="icon" />
      </div>
    </StyledCheckbox>
  );
}

export type IndeterminateCheckboxProps =
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  & {
    className?: string,
    isChecked: boolean | 'indeterminate',
    colorTheme?: ThemeColorName
  }


export const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps> = ({ className, isChecked, ...props }) => {
  const [checkboxRef, setCheckboxRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if(checkboxRef) {
      checkboxRef.indeterminate = isChecked === 'indeterminate';
    }
  }, [checkboxRef, isChecked]);

  return (
    <StyledCheckbox className={classNames(className, { checked: isChecked })} >
      <div className="checkbox-border">
        <input
          className="hidden-checkbox"
          ref={setCheckboxRef}
          type="checkbox"
          defaultChecked={isBoolean(isChecked) ? isChecked : undefined}
          {...props}
        />

        <FontAwesomeIcon icon={isChecked === 'indeterminate' ? SolidIcons.faMinus : SolidIcons.faCheck} className="icon" />
      </div>
    </StyledCheckbox>
  );
}