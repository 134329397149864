import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
// import { MainRouter } from './MainRouter';
import { SystemInitializer } from './system-init/SystemInitializer';

window.addEventListener('DOMContentLoaded', async () => {
  // initialize and wait for all required polyfills to be loaded

  const rootElem = document.querySelector('#app-root');

  
  ReactDOM.render(
    React.createElement(SystemInitializer),
    rootElem
  );

})