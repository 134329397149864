import { useState, useCallback } from 'react';

export const useForceUpdate = () => {
  const [, setTick] = useState(0);
  return useCallback(() => setTick(prev => prev + 1), []);
}

export const useUpdateTrigger = (): [() => void, number] => {
  const [tick, setTick] = useState(0);
  return [useCallback(() => setTick(prev => prev + 1), []), tick];
}