import React, { useCallback, useState, useEffect, useMemo, } from 'react';

import { styled, zIndex } from '@zone-explorer/core-ui-components/theme';
import { useElementDimensions } from '@zone-explorer/core-ui-components/common';
import { LoadingSpinner, SimpleCard } from '@zone-explorer/core-ui-components/components';


type OverlayProps = {
  className?: string,
  onOverlayClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  isShown: boolean,
  containerRef?: React.RefObject<Element>
}

const Overlay = React.memo<OverlayProps>(({ containerRef, className, isShown, children, onOverlayClick }) => {
  const [setElem, dimensions] = useElementDimensions(true);
  useEffect(() => {
    if (containerRef?.current) setElem(containerRef.current);
  }, [containerRef?.current]);


  const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    width: dimensions?.width,
    height: dimensions?.height,
    top: dimensions?.top,
    bottom: dimensions?.bottom,
    left: dimensions?.left,
    right: dimensions?.right,
    zIndex: zIndex.globalOverlay,
    display: isShown ? 'block' : 'none'
  };

  return (
    <div style={overlayStyle} className={className} onClick={onOverlayClick}>
      {children}
    </div>
  );
});



const StyledLoadingOverlay = styled.div`
  width: 100%;
  height: 100%;

  background-color: #AAAAAAAA;
  backdrop-filter: blur(5px);

  display: flex;
  align-items: center;
  justify-content: center;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    max-width: 90vw;
    padding: 0.5rem;
  }

  .message { margin-top: 0.5rem; }
`;


type UseOverlayInit = {
  containerRef?: React.RefObject<Element>,
  children?: React.ReactNode,
  onOverlayClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  className?: string,
  initiallyShown?: boolean,
}

export const useOverlay = ({ className, initiallyShown, containerRef, children, onOverlayClick }: UseOverlayInit) => {
  const [isShown, setIsShown] = useState(initiallyShown ?? false);

  const overlay = (
    <Overlay {...{ className, isShown, containerRef, onOverlayClick}}>
      {children}
    </Overlay>
  );

  return {
    overlay,
    isShown,
    show: useCallback(() => setIsShown(true), []),
    hide: useCallback(() => setIsShown(false), []),
    toggle: useCallback(() => setIsShown(prev => !prev), []),
  }
}

type UseLoadingOverlayInit = {
  containerRef: React.RefObject<Element>,
  className?: string,
  initialState?: {
    isShown?: boolean,
    message?: string
  }
}

export const useLoadingOverlay = ({ className, initialState, containerRef }: UseLoadingOverlayInit) => {
  const [message, setMessage] = useState(initialState?.message ?? '');
  const overlay = useOverlay({
    className,
    containerRef,
    initiallyShown: initialState?.isShown,
    children: useMemo(() => (
      <StyledLoadingOverlay>
        <SimpleCard className="content-wrapper">
          <LoadingSpinner />
          <span className="message">{message}</span>
        </SimpleCard>
      </StyledLoadingOverlay>
    ), [message])
  });

  return {
    ...overlay,
    message,
    setMessage
  }
}