// themed-styled-component.ts
// tslint:disable:no-duplicate-imports
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';
import { Theme } from '.';

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  useTheme,
  createGlobalStyle
  // ... etc
} = (styledComponents as any as ThemedStyledComponentsModule<Theme>);

export { css, keyframes, ThemeProvider, styled, useTheme, createGlobalStyle };
export default styled;