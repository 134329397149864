

export const ThemeColorNames = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'empty',
]

export type ThemeColorName =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'empty'


export interface ThemeColors {
  primary: string,
  secondary: string,
  success: string,
  danger: string,
  warning: string,
  info: string,
  empty: string
}

export interface ThemeSizes {
  small: string,
  medium: string,
  large: string,
  extraLarge: string,
}

export interface Theme {
  bgColor: string,
  colors: ThemeColors & {
    hoverPrimary: string, 
    disabledPrimary: string, 
  },
  // breakpoints: Breakpoints,
  text: {
    colors: ThemeColors,
    sizes: ThemeSizes,
    defaultFont?: string
  }
}


export type ThemedComponentProps = { theme: Theme, colorTheme?: ThemeColorName };

export const pickColor = ({ theme, colorTheme = 'primary' }: ThemedComponentProps) => theme.colors[colorTheme];
export const pickTextColor = ({ theme, colorTheme = 'primary' }: ThemedComponentProps) => theme.text.colors[colorTheme];
// export const pickColor = (theme: Theme, colorTheme: ThemeColorName = 'primary') => theme.colors[colorTheme];
// export const pickTextColor = (theme: Theme, colorTheme: ThemeColorName = 'primary') => theme.text.colors[colorTheme];
