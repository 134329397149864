import React from 'react';
import { styled } from '../../theme';

import { List, ListItem, Checkbox } from '..';
import { TreeNode } from './Tree';


const StyledTreeSelect = styled.div`
`;


export type TreeSelectData = {
  isSelected: boolean
  isChildSelected: boolean
}


export type TreeSelectProps<D extends object> = {
  roots: TreeNode<D>[]
  renderContent: (node: TreeNode<D>) => React.ReactNode,
  onSelectedChange?: (node: TreeNode<D>, isSelected: boolean) => void
}

export function TreeSelect<D extends object>({ roots, renderContent, onSelectedChange }: TreeSelectProps<D>) {
  console.log(roots)
  return (
    <StyledTreeSelect>
      <List>
        {
          roots.map((root, idx) => {
            return <TreeElemRenderer
              key={`node-0-${idx}`}
              node={root}
              renderContent={renderContent}
              level={1}
              onSelectedChange={onSelectedChange}
            />
          })
        }
      </List>
    </StyledTreeSelect>
  );
}


type TreeElemRendererProps<D extends object> = {
  level: number,
  node: TreeNode<D>,
  renderContent: (node: TreeNode<D>) => React.ReactNode,
  onSelectedChange?: (node: TreeNode<D>, isSelected: boolean) => void
}


function TreeElemRenderer<D extends object>(props: TreeElemRendererProps<D>) {
  if (props.node.isLeaf) {
    return <TreeItemRenderer {...props} />;
  } else {
    return <TreeNodeRenderer {...props} />;
  }
}


type TreeNodeRendererProps<D extends object> = {
  level: number,
  node: TreeNode<D>,
  renderContent: (node: TreeNode<D>) => React.ReactNode,
  onSelectedChange?: (node: TreeNode<D>, isSelected: boolean) => void
}

function TreeNodeRenderer<D extends object>(props: TreeNodeRendererProps<D>) {
  const onSelect = () => {
    // console.log(node, node.children, (node as any).parent);
    // if (onSelectedChange) onSelectedChange(node, node.isSelected ? true : !node.isSelected);
  }
  onSelect

  return (
    <>
      <TreeItemRenderer {...props} />
      <List style={{ marginLeft: `${props.level * 20}px` }}>
        {
          props.node.children!!.map((child, idx) => (
            <TreeElemRenderer
              key={`node-${props.level}-${idx}`}
              node={child}
              renderContent={props.renderContent}
              level={props.level + 1}
              onSelectedChange={props.onSelectedChange}
            />
          ))
        }
      </List>
    </>
  );

}


type TreeItemRendererProps<D extends object> = {
  level: number,
  node: TreeNode<D>,
  renderContent: (node: TreeNode<D>) => React.ReactNode,
  onSelectedChange?: (node: TreeNode<D>, isSelected: boolean) => void
}

function TreeItemRenderer<D extends object>({ node, renderContent }: TreeItemRendererProps<D>) {
  const onSelect = () => {
    console.log(node, node.children, (node as any).parent);
    // if (onSelectedChange) onSelectedChange(node, node.isSelected ? true : !node.isSelected);
  }

  // const [content, setContent] = useState<React.ReactNode>(null)
  // useEffect(() => {
  //   renderContent(node)
  //     .then(setContent)
  // }, [node, renderContent])

  return (
    <ListItem onClick={onSelect}>
      <Checkbox checked={true} />
      {renderContent(node)}
    </ListItem>
  );
}