// import { styled, ThemeColorName, pickTextColor, pickColor } from '../../theme';

// import * as _solid from '@fortawesome/free-solid-svg-icons';
import * as _solid from '@fortawesome/pro-solid-svg-icons';
export const SolidIcons = _solid;

import * as _light from '@fortawesome/pro-light-svg-icons';
export const LightIcons = _light;

import * as _regular from '@fortawesome/pro-regular-svg-icons';
export const RegularIcons = _regular;

import * as _duotone from '@fortawesome/pro-duotone-svg-icons';
export const DuoToneIcons = _duotone;


// https://github.com/FortAwesome/react-fontawesome#introduction
