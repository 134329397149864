import React from 'react';
import { Result, Deferred } from '../../common';
import { DialogContext } from './DialogContext';
import { styled } from '../../theme';

export type DialogResult<T, E> =
  Result<{ cancelled: true } | { value: T }, E>


export type DismissableDialogOptions<T = any> =
  // { isDismissable: true, dismissValue: NonNullable<T> }
  { isDismissable: true, dismissValue: T }

export type DialogOptions<T = any> =
  DismissableDialogOptions<T>
  | { isDismissable?: false | undefined }

export function isDismissableDialogOptions<T>(opt: DialogOptions<T>): opt is DismissableDialogOptions<T> {
  return !!opt.isDismissable;
}

export function useDialog<T = any, E = any>(
  componentCreator: (dialogResult: Deferred<Result<T, E>>) => React.ReactElement,
  dialogOptions: DialogOptions<T> = {},
  dependencies: any[] = []
) {
  const ctx = React.useContext(DialogContext);

  return React.useCallback(async (key?: string) => {
    return ctx.display(key, componentCreator, dialogOptions);
  }, [...dependencies, dialogOptions]);
}



export const DialogCard = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.74) 7px 4px 6px 0px;

  display: flex;
  flex-direction: column;
  /* max-height: calc(100vh - 5%);
  height: calc(100vh - 5%); */
  max-height: 95vh;
  /* height: 95vh; */
  width: 95%;
  margin: 0 auto;
  margin-top: 2vh;
`;

export const DialogHeader = styled.div`
  background-color: #F1F1F1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.5rem;
`;

export const DialogContent = styled.div`
  flex-grow: 1;
  /* height: fit-content; */
  overflow: auto;
  padding: 0.5rem;
  margin-bottom: 5px;
`;

export const DialogFooter = styled.div`
  padding: 0.5rem;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.74);
`;
