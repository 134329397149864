import React, { useEffect, useContext } from 'react';
import { Card } from '@zone-explorer/core-ui-components/components';
import { styled, ThemeProvider, DefaultTheme, ZoneGlobalStyle } from '@zone-explorer/core-ui-components/theme';
import { registerWorkers } from './register-workers';
import { MainRouter } from './MainRouter';
import { GlobalLoadingOverlay, GlobalLoadingOverlayContext } from '@webapp/explorer/app-shell/components/global-loading-screen/GlobalLoadingOverlay';
// import { isDevEnv } from '@core-lib/lib/util';


const StyledSystemInitializer = styled.div`
  width: 100vw;
  height: 100vh;


  .card {
    max-width: 300px;
    width: 95%;
    margin: 0 auto;
    margin-top: 5rem;
    padding: 1rem;

    display: flex;
    flex-direction: row;
  }
`;

type SystemInitializerState =
  | { status: 'initializing', message?: string }
  | { status: 'error', message?: React.ReactNode }
  | { status: 'done' }

export const SystemInitializer: React.FC = () => {
  const [state, setState] = React.useState<SystemInitializerState>({ status: 'initializing' });

  React.useEffect(() => {
    setState({ status: 'initializing', message: 'Service Worker wird geladen...' });

    // if (isDevEnv()) return setState({ status: 'done' });


    registerWorkers()
      .then(res => {
        if (res.success) {
          // setTimeout(() => {
          setState({ status: 'done' });
          // }, [20000])
        } else {
          setState({ status: 'error', message: res.error.message });
        }
      });
  }, []);


  const containerRef = React.useRef(null);

  return (
    <StyledSystemInitializer ref={containerRef}>
      <ThemeProvider theme={DefaultTheme}>
        <GlobalLoadingOverlay containerRef={containerRef}>
            
            <ZoneGlobalStyle />
            <SystemInitializerContent sysState={state} />

        </GlobalLoadingOverlay>
      </ThemeProvider>
    </StyledSystemInitializer>
  );
}


const SystemInitializerContent: React.FC<{ sysState: SystemInitializerState }> = ({ sysState }): React.ReactElement | null => {
  const overlayCtx = useContext(GlobalLoadingOverlayContext);
  useEffect(() => {
    if (sysState.status === 'initializing') {
      overlayCtx.setMessage('System Komponenten werden geladen...')
      overlayCtx.show();
    } else {
      overlayCtx.hide();
    }
  }, [sysState.status]);

  // let body: React.ReactNode | null = null;
  if (sysState.status === 'done') {
    return <MainRouter />;
  } else if (sysState.status === 'error') {
    return (sysState.message
      ? sysState.message
      : (
        <Card className="card">
          <p>Die Initialisierung der Applikation ist fehlgeschlagen.</p>
          <p>Bitte laden Sie die Seite erneut.</p>
        </Card>
      )
    ) as unknown as React.ReactElement
  }

  return <p></p>;
}