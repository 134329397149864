


export abstract class Option<T> {

  static of<T = any>(value?: null | undefined): None<T>
  static of<T>(value: NonNullable<T>): Some<T>
  static of<T>(value?: NonNullable<T> | null | undefined): Option<T> {
    return (value === null || value === undefined) ? none() : some(value);
  }

  abstract get(): T | null;

  abstract isSome(): this is Some<T>;
  abstract isNone(): this is None<T>;

  isPresent(): this is Some<T> { return this.isSome(); }
  isEmpty(): this is None<T> { return this.isNone(); }

  abstract map<U>(op: (value: T) => U): Option<U>;
  abstract flatMap<U>(op: (value: T) => Option<U>): Option<U>;

  abstract or(opt: Option<T>): Option<T>;
  abstract orElse(op: () => Option<T>): Option<T>;

  abstract unwrap(): T | never
  abstract unwrapOr(opt: T): T
  abstract unwrapOrElse(op: () => T): T

  abstract toString(): string;
}

export class Some<T> extends Option<T> {
  constructor(public readonly value: NonNullable<T>) { super(); }

  get(): T { return this.value }

  isSome(): this is Some<T> { return true; }
  isNone(): this is None<T> { return false; }

  map<U>(op: (value: T) => NonNullable<U>): Some<U> { return some(op(this.value)); }

  flatMap<U>(f: (value: T) => Some<U>): Some<U>
  flatMap<U>(f: (value: T) => None<U>): None<U>
  flatMap<U>(op: (value: T) => Option<U>): Option<U> { return op(this.value); }


  or(_opt: Option<T>): Some<T> { return this; }
  orElse(_op: () => Option<T>): Some<T> { return this; }

  unwrap(): T { return this.value; }
  unwrapOr(_opt: T): T { return this.value; }
  unwrapOrElse(_op: () => T): T { return this.value; }

  toString() { return `Some(${this.value})` }
}

export function some<T>(value: NonNullable<T>) { return new Some<T>(value); }

export class None<T> extends Option<T> {

  get(): null { return null; }

  isSome(): this is Some<T> { return false; }
  isNone(): this is None<T> { return true; }

  map<U>(_op: (value: T) => U): None<U> { return none<U>(); }
  flatMap<U>(_op: (value: T) => Option<U>): None<U> { return none<U>(); }

  or(opt: Some<T>): Some<T>
  or(opt: None<T>): None<T>
  or(opt: Option<T>): Option<T> { return opt; }

  orElse(op: () => Some<T>): Some<T>
  orElse(op: () => None<T>): None<T>
  orElse(op: () => Option<T>): Option<T> { return op(); }

  unwrap(): never { throw new Error('Trying to unwrap None'); }
  unwrapOr(opt: T): T { return opt; }
  unwrapOrElse(op: () => T): T { return op(); }


  toString() { return 'None'; }
}

const NONE = new None<any>();
export function none<T>() { return NONE as None<T>; }