import { Option, some, none, Some, None } from './Option';

export interface Result<T = any, E = any> {
  isOk(): this is Ok<T, E>
  isErr(): this is Err<T, E>

  getValue(): Option<T>;
  getError(): Option<E>;

  map<U>(fn: (val: T) => U): Result<U, E>
  mapErr<U>(fn: (err: E) => U): Result<T, U>

  // and<U>(res: Result<U, E>): Result<U, E>
  // andThen<U>(op: (val: T) => Result<U, E>): Result<U, E>

  or(result: Result<T, E>): Result<T, E>
  orElse<U>(op: (err: E) => Result<U, E>): Result<U, E>

  unwrap(): T | never
  unwrapOr(opt: T): T
  unwrapOrElse(op: (err: E) => T): T
}

export const ok = <T, E>(value: NonNullable<T>) => new Ok<T, E>(value);

export class Ok<T, E> implements Result<T, E> {

  constructor(public readonly value: NonNullable<T>) { }

  isOk(): this is Ok<T, E> { return true; }
  isErr(): this is Err<T, E> { return false; }

  getValue(): Some<T> { return some(this.value); }
  getError(): None<E> { return none(); }


  map<U>(fn: (val: T) => NonNullable<U>): Result<U, E> { return ok(fn(this.value)); }
  mapErr<U>(_fn: (err: E) => U): Result<T, U> { return this as unknown as Ok<T, U> }

  or(_result: Result<T, E>): Result<T, E> { return this; }
  orElse<U>(_op: (err: E) => Result<U, E>): Result<U, E> { return this as unknown as Result<U, E> }

  unwrap() { return this.value; }
  unwrapOr(_opt: T): T { return this.value; }
  unwrapOrElse(_op: (err: E) => T): T { return this.value; }

  toString() { return `Ok(${this.value})`; }
}

export const err = <T, E>(error: NonNullable<E>) => new Err<T, E>(error);

export class Err<T, E> implements Result<T, E> {

  constructor(public readonly error: NonNullable<E>) { }

  isOk(): this is Ok<T, E> { return false; }
  isErr(): this is Err<T, E> { return true; }

  getValue(): None<T> { return none(); }
  getError(): Some<E> { return some(this.error); }

  map<U>(_fn: (val: T) => U): Result<U, E> { return this as unknown as Err<U, E>; }
  mapErr<U>(fn: (err: E) => NonNullable<U>): Result<T, U> { return err(fn(this.error)); }

  or(result: Result<T, E>): Result<T, E> { return result; }
  orElse<U>(op: (err: E) => Result<U, E>): Result<U, E> { return op(this.error); }

  unwrap(): never { throw this.error; }
  unwrapOr(opt: T): T { return opt; }
  unwrapOrElse(op: (err: E) => T): T { return op(this.error); }

  toString() { return `Err(${this.error})`; }
}
