import React from 'react';


export type UpdateAuthContextType = (setter: AuthContextType | ((prev: AuthContextType) => AuthContextType)) => void;

export type AuthContextType =
  | { status: 'unauthorized' }
  | { status: 'authenticated' }


const defaultContext: AuthContextType & { update: UpdateAuthContextType } = {
  get status(): AuthContextType['status'] { throw new Error('AuthContext is not initialized!') },
  update() { throw new Error('AuthContext is not initialized!') }
}

export const AuthContext = React.createContext<AuthContextType & { update: UpdateAuthContextType }>(defaultContext);
