import { useState, SetStateAction, Dispatch, useLayoutEffect } from 'react';

export type ElementDimensions = {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseElementDimensionsHook = [
  Dispatch<SetStateAction<Element | null>>,
  ElementDimensions | null,
  Element | null
]

export const getElementDimensions = (elem: Element) => {
  const { width, height, top, left, x, y, bottom, right } = elem.getBoundingClientRect();

  return {
    width,
    height,
    top: top ?? x,
    left: left ?? y,
    x: x ?? top,
    y: y ?? left,
    bottom,
    right
  }
}

export function useElementDimensions(liveUpdate: boolean = true): UseElementDimensionsHook {
  const [dimensions, setDimensions] = useState<ElementDimensions | null>(null);
  const [elem, setElem] = useState<Element | null>(null);

  useLayoutEffect(() => {
    if (!elem) return;

    const measure = () => {
      window.requestAnimationFrame(() => setDimensions(getElementDimensions(elem)));
    }

    measure();

    if (liveUpdate) {
      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      }
    }
    return;
  }, [elem]);

  return [
    setElem,
    dimensions,
    elem
  ];
}


export default useElementDimensions;