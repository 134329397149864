import { Theme, ThemeColors, ThemeSizes } from "./spec";


const Colors: ThemeColors = {
  primary: '#00b0ad',
  secondary: 'gray',
  success: 'green',
  danger: 'red',
  warning: 'yellow',
  info: 'cyan',
  empty: 'white'
}

const TextColors: ThemeColors = {
  primary: 'white',
  secondary: 'black',
  success: 'black',
  danger: 'white',
  warning: 'black',
  info: 'black',
  empty: 'black'
}

const Sizes: ThemeSizes = {
  small: '12px',
  medium: '16px',
  large: '20px',
  extraLarge: '24px'
}

export const LegacyTheme: Theme = {
  bgColor: 'white',
  colors: {
    ...Colors, 
    hoverPrimary: Colors.primary,
    disabledPrimary: Colors.primary,
  },
  text: {
    colors: TextColors,
    sizes: Sizes
  }
}