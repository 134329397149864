import React from 'react';
import { useLoadingOverlay } from './LoadingOverlay';

type GlobalLoadingOverlayContextType = {
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  isShown: boolean,
  show: () => void,
  hide: () => void,
  toggle: () => void
}


const notInitialized = () => new Error('GlobalLoadingOverlayContext is not initialized!');

export const GlobalLoadingOverlayContext = React.createContext<GlobalLoadingOverlayContextType>({
  get message(): string { throw notInitialized() },
  setMessage: () => { throw notInitialized() },
  get isShown(): boolean { throw notInitialized() },
  show: () => { throw notInitialized() },
  hide: () => { throw notInitialized() },
  toggle: () => { throw notInitialized() }
});


export const GlobalLoadingOverlay: React.FC<{ containerRef: React.RefObject<Element> }> = ({ containerRef, children }) => {
  const {overlay, ...ctx} = useLoadingOverlay({ containerRef });
  

  return (
    <GlobalLoadingOverlayContext.Provider value={ctx}>
      {children}
      {overlay}
    </GlobalLoadingOverlayContext.Provider>
  );
}